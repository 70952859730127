/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


$('.menu-toggle').click(function(){
  $('.hamburger', this).toggleClass('is-active');
  $('.menu-toggle-content').toggleClass('is-active');
});

$('.nav-primary-overlay').click(function(){
  $('.hamburger').toggleClass('is-active');
  $('.menu-toggle-content').toggleClass('is-active');
});

$('.slider-galerij').each(function(index) {
  $(this).slick({
    arrows: true,
    dots: true,
    prevArrow: '<div class="slick-arrow arrow-left d-none d-md-block"><button class="btn btn-primary btn-sm"><i class="fas fa-arrow-left"></i></button></div>',
    nextArrow: '<div class="slick-arrow arrow-right d-none d-md-block"><button class="btn btn-primary btn-sm"><i class="fas fa-arrow-right"></i></button></div>',
  });
});

$('.slider-logos').each(function(index) {
  $(this).slick({
    arrows: true,
    dots: false,
    prevArrow: '<div class="slick-arrow arrow-left"><button class="btn btn-primary btn-sm"><i class="fas fa-arrow-left"></i></button></div>',
    nextArrow: '<div class="slick-arrow arrow-right"><button class="btn btn-primary btn-sm"><i class="fas fa-arrow-right"></i></button></div>',
    slidesToShow: 6,
    slidesToScroll: 1,
    rows: 0,
    responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      }
    }
  ]
  });
});

$('.slider-quotes').each(function(index) {
  $(this).slick({
    arrows: true,
    dots: true,
    prevArrow: '<div class="slick-arrow arrow-left"><button class="btn btn-light btn-sm"><i class="fas fa-arrow-left"></i></button></div>',
    nextArrow: '<div class="slick-arrow arrow-right"><button class="btn btn-light btn-sm"><i class="fas fa-arrow-right"></i></button></div>',
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: 0,
    responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
  });
});

var feedSessionsHair = new Instafeed({
          get: 'user',
          accessToken: InstagramToken,
          limit: 9,
          target: 'instagram-feed',
          template: '<div class="instafeed-item col alt-col slick-slide-item"><a href="{{link}}" target="_blank" class="instafeed-item-image" style="background-image:url({{image}});"><i class="fab fa-instagram"></i></a></div>',
          after: function(image) {
            
            $('.slider-instafeed').slick({
              infinite: true,
              slidesToShow: 6,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              autoplay: false,
              autoplaySpeed: 0,
              speed: 6000,
              //touchThreshold: 100,
              //edgeFriction: 0,
              //swipeToSlide: true,
              cssEase: 'linear',
              responsive: [
                {
                  breakpoint: 767,
                  settings: {
                    dots: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    autoplay: false,
                    autoplaySpeed: 6000,
                    speed: 400,
                  }
                }
              ]
              });

            }
        });
        feedSessionsHair.run();


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
